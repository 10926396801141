<template>
   <div class="impressum container has-text-centered">
     <div v-if="pending"  class="columns" >
        
            <div class="column">
                    <h1 class="title"> Impressum</h1>
                    <h2 class="subtitle"> letzte Änderung: {{ lastChange }}</h2>
                    
                    <div class="columns ">
                        <div class="textmarkdown column">
                            <vue-simple-markdown :source="source"></vue-simple-markdown>
                        </div>
                    </div>
            </div>
     </div>
     <div v-else class="box">
              <h1 class="title"> Loading . . .</h1>
        </div>
  </div>
</template>

<script>
export default {
    name:"impressum",
    data(){
        return {
            pending: false,
            impressum: null
        }
    },
    computed:{
        source(){
            return this.impressum.Inhalt
        },
        lastChange(){
            return this.impressum.updated_at
        }
    },
    async mounted(){
        this.pending = false
        this.impressum = await this.$get("/impressum")
        this.pending = true
    }
}
</script>

<style>
@media screen and (max-width: 450px) {
  .textmarkdown {
     max-width: 40ch;
  }
}
@media screen and (max-width: 320px) {
  .textmarkdown {
     max-width: 36ch;
  }
}

</style>